import './bootstrap';
import '../scss/app.scss';

import { createApp, h } from 'vue';
import {createInertiaApp, Head} from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import mitt from 'mitt';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import vuetify from "./vuetify";
import './Assets/fontawesome-pro-6.0.0-beta2-web/css/all.css';
import './Assets/fontawesome-pro-6.0.0-beta2-web/css/fontawesome.css';

const appName = import.meta.env.VITE_APP_NAME || 'Seafish';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const emitter = mitt()
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
        app.provide('emitter', emitter);
        app.component('Head', Head)
        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
